import { ChartIcon } from '../icons/bottomnav/chart-icon';
import { CustomerIcon } from '../icons/bottomnav/customer-icon';
import { EnquiryIcon } from '../icons/bottomnav/enquiry-icon';
import { ExpenseIcon } from '../icons/bottomnav/expense-icon';
import { HomeIcon } from '../icons/bottomnav/home-icon';
import { InventoryIcon } from '../icons/bottomnav/inventory-icon';
import { InvoiceIcon } from '../icons/bottomnav/invoice-icon';
import { JobIcon } from '../icons/bottomnav/job-icon';
import { ProductIcon } from '../icons/bottomnav/product-icon';
import { PurchaseIcon } from '../icons/bottomnav/purchase-icon';
import { QuoteIcon } from '../icons/bottomnav/quote-icon';
import { SupplierIcon } from '../icons/bottomnav/supplier-icon';
import { TeamIcon } from '../icons/bottomnav/team-icon';
import { TimerIcon } from '../icons/bottomnav/timer-icon';

export const NAV_ITEMS_DEFAULT = [
  {
    icon: <HomeIcon color="red" />,
    title: 'Home',
    path: '/',
    href: '/',
  },
  {
    icon: <CustomerIcon color="red" />,
    title: 'Clients',
    path: '/clients',
    href: '/clients',
  },
  {
    icon: <EnquiryIcon color="red" />,
    title: 'Enquiries',
    path: '/enquiries',
    href: '/enquiries',
  },
  {
    icon: <QuoteIcon color="red" />,
    title: 'Quotes',
    path: '/quotes',
    href: '/quotes',
  },
  {
    icon: <JobIcon color="red" />,
    title: 'Jobs',
    path: '/jobs',
    href: '/jobs',
  },
  {
    icon: <InvoiceIcon color="red" />,
    title: 'Invoices',
    path: '/invoices',
    href: '/invoices',
  },
  {
    icon: <TeamIcon color="red" />,
    title: 'Teams',
    path: '/teams',
    href: '/teams',
  },
  {
    icon: <SupplierIcon color="red" />,
    title: 'Suppliers',
    path: '/suppliers',
    href: '/suppliers',
  },
  {
    icon: <ProductIcon color="red" />,
    title: 'Products & Services',
    path: '/products-services',
    href: '/products-services',
  },
  {
    icon: <InventoryIcon color="red" />,
    title: 'Inventories',
    path: '/inventories',
    href: '/inventories',
  },
  {
    icon: <PurchaseIcon color="red" />,
    title: 'Purchase Orders',
    path: '/purchase-orders',
    href: '/purchase-orders',
  },
  {
    icon: <TimerIcon color="red" />,
    title: 'Timesheets',
    path: '/timesheets',
    href: '/timesheets',
  },
  {
    icon: <ExpenseIcon color="red" />,
    title: 'Expenses',
    path: '/expenses',
    href: '/expenses',
  },
  {
    icon: <ChartIcon color="red" />,
    title: 'Reports',
    path: '/reports',
    href: '/reports',
  },
];

export const NAV_ITEMS_CUSTOMER = [
  {
    icon: <EnquiryIcon color="red" />,
    title: 'Enquiries',
    path: '/customer-portal/enquiries',
    href: '/customer-portal/enquiries',
  },
  {
    icon: <QuoteIcon color="red" />,
    title: 'Quotes',
    path: '/customer-portal/quotes',
    href: '/customer-portal/quotes',
  },
  {
    icon: <JobIcon color="red" />,
    title: 'Jobs',
    path: '/customer-portal/jobs',
    href: '/customer-portal/jobs',
  },
  {
    icon: <InvoiceIcon color="red" />,
    title: 'Invoices',
    path: '/customer-portal/invoices',
    href: '/customer-portal/invoices',
  },
];
