import { BottomNavItem } from './bottomnav-item';
import { NAV_ITEMS_DEFAULT, NAV_ITEMS_CUSTOMER } from './data';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { AppModules } from '@/constants/AppModules';
import { PermissionActions } from '@/constants/PermissionActions';

interface BottomNavProps {
  portalType: 'default' | 'customer'; // Define types for portalType
  referenceKey?: string;
}

export const BottomNavMenu: React.FC<BottomNavProps> = ({
  portalType,
  referenceKey,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const userPermissions = user?.permissions || [];

  const MODULE_PERMISSIONS_MAP: Record<string, AppModules> = {
    '/enquiries': AppModules.REQUESTS,
    '/clients': AppModules.CLIENTS,
    '/quotes': AppModules.QUOTES,
    '/suppliers': AppModules.SUPPLIERS,
    '/products-services': AppModules.PRODUCTS_SERVICES,
    '/inventories': AppModules.INVENTORIES,
    '/jobs': AppModules.JOBS,
    '/purchase-orders': AppModules.PURCHASE_ORDERS,
    '/teams': AppModules.TEAM_MEMBERS,
    '/timesheets': AppModules.TIMESHEETS,
    '/expenses': AppModules.EXPENSES,
    '/invoices': AppModules.INVOICES,
    '/reports': AppModules.REPORTS,
  };

  const NAV_ITEMS =
    portalType === 'customer' ? NAV_ITEMS_CUSTOMER : NAV_ITEMS_DEFAULT;

  const FILTERED_NAV_ITEMS =
    portalType === 'customer'
      ? NAV_ITEMS
      : NAV_ITEMS.filter((nav_item) => {
        const appModule = MODULE_PERMISSIONS_MAP[nav_item.path];
        return appModule
          ? userPermissions.includes(`${appModule}.${PermissionActions.Read}`)
          : true; // Keep items without module mapping
      });

  const MODIFIED_NAV_ITEMS = FILTERED_NAV_ITEMS.map((nav_item) => {
    if (portalType === 'customer') {
      // Append the reference_key as a query parameter
      return {
        ...nav_item,
        path: `${nav_item.path}`,
        href: `${nav_item.href}?reference_key=${referenceKey}`,
      };
    }
    return nav_item;
  });

  return (
    <div className="gap-2 px-2 md:gap-3 lg:gap-5 flex justify-center flex-wrap bg-light-primary-100 pb-5 pt-5">
      {MODIFIED_NAV_ITEMS.map((nav_item) => (
        <BottomNavItem
          key={nav_item.title}
          icon={nav_item.icon}
          title={nav_item.title}
          path={nav_item.path}
          href={nav_item.href}
        />
      ))}
    </div>
  );
};
